<template>
    <Dialog ref="dialog" :config="config" :beforeClose="beforeClose" :visible='visible' :cancelDialog="cancelDialog" :confirmDialog='confirmDialog'>
        <p>1. 小企业会计准则是按照2013年版 《小企业会计准则》设 置，为了使报表取数准确，系统不允许增加一级科目， 但可以增加二级、三级和四级科目。</p> 
        <p>2. 企业会计准则是按照2007年版 《企业会计准则》设置， 系统可以增加一级科目，也可以增加二级、三级和四级 科目。</p>
    </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog";

export default {
  props: {
    rowData:{
      type: Object,
    },
    type:{
      type: String,
    },
    visible: {
      type: Boolean,
    },
    cancel: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    Dialog,
  },
  data() {
    return {
      config: {
        top: '20vh',
        width: '21rem',
        title: '会计准则说明',
        center: true,
        btnTxt: ['确定'],
      },
    };
  },
  methods: {
    beforeClose(){
      this.cancel();
      console.log('关闭前');
    },
    cancelDialog(){
      this.cancel();
    },
    confirmDialog(){
        this.cancel();
      },
  }
};
</script>